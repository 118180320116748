import {LitElement, html, customElement} from '../../node_modules/lit-element';

@customElement('nonstop-agent')
export class AgentElement extends LitElement {

    protected override createRenderRoot() {
        return this;
    }
    
    override render() {
        return html`
            <div id="agent" class="section db" style="background-image:url('../../uploads/parallax_02.png');">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="message-box">
                                <h2>Cherry Dawkins</h2>
                                <p class="lead">Licensee-in-charge and Director of Nonstop Real Estate</p>

                                <p></p>
                            </div>
                        </div>
                        <div class="col-md-2">
<!--                            <div class="post-media wow fadeIn" style="max-width: 130px;">-->
<!--                                <img src="uploads/director-cherry.jpg" alt="" class="img-responsive">-->
<!--                            </div>-->
                        </div>
                        <div class="col-md-4">
                            <div class="agencies_meta clearfix">
<!--                                <span><i class="fa fa-envelope "></i> <a href="mailto:info@nonstoprealestate.com.au">info@nonstoprealestate.com.au</a></span>-->
<!--                                <span><i class="fa fa-link "></i> <a href="#">www.nonstoprealestate.com.au</a></span>-->
                                <span><i class="fa fa-phone-square "></i> 0468 366 567</span>
                                <!--                    <span><i class="fa fa-print "></i> +1 232 444 55 66</span>-->
                                <!--                    <span><i class="fa fa-facebook-square "></i> <a href="#">facebook.com/nonstoprealestate</a></span>-->
                                <!--                    <span><i class="fa fa-twitter-square "></i> <a href="#">twitter.com/nonstoprealestate</a></span>-->
                                <!--                    <span><i class="fa fa-linkedin-square "></i> <a href="#">linkedin.com/nonstoprealestate</a></span>-->
                                <span><i class="fa fa-instagram "></i> <a href="instagram.com/nonstoprealestate">Instagram</a></span>
                                
                                <span class="dropdown-toggle" id="wechatDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fa fa-wechat "></i> <a href="#">Wechat</a>
                                </span>
                                <form action="#" class="p-color-bg dropdown-menu tran3s" aria-labelledby="wechatDropdown">
                                    <img id="wechat" class="small" src="../../images/wechat-qr-code.png" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'nonstop-agent': AgentElement;
    }
}
